import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import "../styles/hero.scss"

const Hero = () => {
    const data = useStaticQuery(graphql`
        query HeroQuery {
            heroJson {
                title
                subTitle
            }
        }
    `)

    return (
        <section className="hero">
            <div className="heroImage"></div>
            <div className="heroContent">
                <h1>{data.heroJson.title}</h1>
                <div className="tagline">
                    {data.heroJson.subTitle.map((item, id) => (
                        <div key={id}>
                            <h3>{item}</h3>
                            <span className="sep">&lowast;</span>
                        </div>
                    ))}
                </div>
            </div>
            <div className="heroCall">
                <button className="large background1">
                    <div>Get In Touch</div>
                </button>
            </div>
            <div id="scrollArrow" className="arrow"></div>
        </section>
    )
}

export default Hero
