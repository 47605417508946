import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { isMobile } from "react-device-detect"
import { graphql, useStaticQuery } from "gatsby"

import "../styles/contact.scss"

import Section from "../components/section"
import Google from "../imgs/social/google.svg"
import Facebook from "../imgs/social/facebook.svg"
// import Instagram from "../imgs/social/instagram.svg"

const API_GATEWAY_URL = "https://56u4870p03.execute-api.eu-west-1.amazonaws.com/prod"

export default () => {
    const data = useStaticQuery(graphql`
        query ContactQuery {
            contactJson {
                phone
                email
                facebook
                facebookApp
                google
                instagram
            }
        }
    `)

    const [submitted, setSubmitted] = useState(false)
    const [sendBttn, setSendBttn] = useState("Send Message")
    const {
        register,
        handleSubmit,
        setError,
        errors,
        reset,
        formState: { isSubmitting },
    } = useForm()

    const onSubmit = async data => {
        try {
            setSendBttn("Sending")
            const honey = document.getElementById("e-mail")
            if (honey.nodeValue == null) {
                await fetch(API_GATEWAY_URL, {
                    method: "POST",
                    mode: "cors",
                    cache: "no-cache",
                    body: JSON.stringify(data),
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                })
            }
            setSubmitted(true)
            setSendBttn("Send Message")
            reset()
        } catch (error) {
            setError("submit", "submitError", `Oops! There seems to be an issue! ${error.message}`)
        }
    }

    const showSubmitError = msg => <p className="msg-error">{msg}</p>

    const showThankYou = (
        <div className="thanks marBotLarge">
            <p>Awesome! Your message was sent.</p>
            <button type="button" onClick={() => setSubmitted(false)}>
                Send another message?
            </button>
        </div>
    )

    const showForm = (
        <form onSubmit={handleSubmit(onSubmit)} method="post" className="contactForm marBotLarge">
            <div className="honey">
                <input type="text" id="e-mail" name="e-mail" autoComplete="off" />
                <label htmlFor="e-mail">E-Mail:</label>
            </div>
            <div className="floatingLabel">
                <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Full Name"
                    ref={register({ required: "Name is required" })}
                    disabled={isSubmitting}
                />
                <label htmlFor="name">Full Name:</label>
                {errors.name && <div className="error">{errors.name.message}</div>}
            </div>
            <div className="floatingLabel">
                <input
                    type="text"
                    id="email"
                    name="email"
                    placeholder="Email"
                    ref={register({ required: "Email is required" })}
                    disabled={isSubmitting}
                />
                <label htmlFor="email">Email:</label>
                {errors.email && <div className="error">{errors.email.message}</div>}
            </div>
            <div className="floatingLabel">
                <input
                    type="text"
                    id="phone"
                    name="phone"
                    placeholder="Phone"
                    ref={register()}
                    disabled={isSubmitting}
                />
                <label htmlFor="phone">Phone:</label>
            </div>
            <div className="floatingLabel wide">
                <textarea
                    id="message"
                    name="message"
                    placeholder="Message"
                    rows="1"
                    ref={register({ required: "Message is required" })}
                    disabled={isSubmitting}
                />
                <label htmlFor="message">Message:</label>
                {errors.message && <div className="error">{errors.message.message}</div>}
            </div>
            <button type="submit" disabled={isSubmitting} className="large background3">
                <div>{sendBttn}</div>
            </button>
        </form>
    )

    return (
        <Section id="contact" sectionClass="contact" headerTitle="Get in Touch">
            {errors && errors.submit && showSubmitError(errors.submit.message)}
            {submitted ? showThankYou : showForm}

            <div className="marBotLarge textCentre">
                <h2>Prefer to Give Me a Call?</h2>
                <h2>
                    <a id="contactPhone" href={`tel:${data.contactJson.phone}`}>
                        {data.contactJson.phone}
                    </a>
                </h2>
            </div>

            <div className="textCentre">
                <h2 className="marBotMed">Follow Me On Social Media:</h2>
                <div className="socialIcons">
                    <div className="facebook">
                        <a href={isMobile ? data.contactJson.facebookApp : data.contactJson.facebook}>
                            <img
                                src={Facebook}
                                title="Swift Walkies on Facebook"
                                alt="Find Swift Walkies on Facebook"
                            />
                        </a>
                    </div>
                    <div className="google">
                        <a href={data.contactJson.google}>
                            <img src={Google} title="Swift Walkies on Google" alt="Find Swift Walkies on Google" />
                        </a>
                    </div>
                    {/* <div className="instagram">
                        <a href={data.contactJson.instagram}>
                            <img
                                src={Instagram}
                                title="Swift Walkies on Instagram"
                                alt="Find Swift Walkies on Instagram"
                            />
                        </a>
                    </div> */}
                </div>
            </div>
        </Section>
    )
}
