import React from "react"

export default props => {
    return (
        <div className={`servicesItem ${props.classes}`}>
            <div className="icon">
                <img src={require(`../imgs/${props.icon}`)} alt={props.title} />
            </div>
            <header>
                <h3>{props.title}</h3>
                <h3>
                    <span className="textPlain">£</span>
                    {props.price}
                </h3>
            </header>
            <ul className="serviceItemDesc">
                {props.items.map(item => (
                    <li key={item}>{item}</li>
                ))}
            </ul>
            {props.linkedService && (
                <div className="linkedService">
                    <header>
                        <h3>{props.linkedService.title}</h3>
                        <h3>
                            <span className="textPlain">+£</span>
                            {props.linkedService.price}
                        </h3>
                    </header>
                    <p>{props.linkedService.description}</p>
                </div>
            )}
        </div>
    )
}
