import React from "react"

import "../styles/form.scss"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Hero from "../components/hero"
import Services from "../components/services"
import FAQ from "../components/faq"
import Contact from "../components/contact"

const HomePage = () => {
    return (
        <Layout>
            <SEO title="Home" />
            <Hero />
            <Services />
            <FAQ />
            <Contact />
        </Layout>
    )
}

export default HomePage
