import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import "../styles/faq.scss"

import Section from "../components/section"

const FAQ = () => {
    const data = useStaticQuery(graphql`
        query FaqQuery {
            faqJson {
                faqs {
                    question
                    answer
                }
            }
        }
    `)

    return (
        <Section sectionClass="faq" headerTitle="F.A.Q">
            <ul>
                {data.faqJson.faqs.map((faq, id) => (
                    <li key={id}>
                        <p className="question">{faq.question}</p>
                        <p className="answer">{faq.answer}</p>
                    </li>
                ))}
            </ul>
        </Section>
    )
}

export default FAQ
