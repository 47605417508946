import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import "../styles/services.scss"

import Section from "../components/section"
import ServiceItem from "../components/serviceItem"

const Services = () => {
    const data = useStaticQuery(graphql`
        query ServiceQuery {
            servicesJson {
                header
                serviceItems {
                    classes
                    items
                    price
                    title
                    icon
                    linkedService {
                        description
                        price
                        title
                    }
                }
            }
        }
    `)

    return (
        <Section contentClasses="wide" sectionClass="services" headerTitle={data.servicesJson.header}>
            <div className="servicesContentWrapper">
                {data.servicesJson.serviceItems.map((service, id) => (
                    <ServiceItem
                        key={id}
                        classes={service.classes}
                        title={service.title}
                        icon={service.icon}
                        price={service.price}
                        items={service.items}
                        linkedService={service.linkedService}
                    />
                ))}
            </div>
        </Section>
    )
}

export default Services
